<template>
  <div class="app">
    <el-card class="box-card">
      <!-- 查询 -->
      <div class="header-item query">
        <el-select v-model="queryDb.storeId" slot="prepend" placeholder="請選擇门店" style="margin-right: 12px">
          <el-option
            v-for="item in storeList"
            :key="item.id"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="queryDb.time"
          type="month"
          placeholder="选择月"
          style="margin-right: 12px">
        </el-date-picker>
        <!-- <el-button type="primary" @click="queryBin" :loading="loading">查詢</el-button> -->
      </div>


      <!-- 日历 -->
      <el-calendar :first-day-of-week="7"
        v-model="time"
        :loading="calendarLoading"
        id="calendar">
        <template
          slot="dateCell"
          slot-scope="{date, data}">
          <div >
            <div class="calendar-day">
              <span v-if="data.day.split('-').slice(1)[0] == time.split('-').slice(1)[0]">
                <h1>{{ data.day.split('-').slice(2).join('-') }}</h1>
              </span>
              <span v-else>
                <h1>{{ data.day.split('-').slice(2).join('-') }}</h1>
              </span>
            </div>
            <div v-if="data.day.split('-').slice(1)[0] == time.split('-').slice(1)[0]">

              <div class="is-selected">
                <el-button size="mini" type="primary" @click.stop="bookingTimeList(data.day.split('-').slice(2).join('-'))">查看坐席</el-button>
              </div>

            </div>
          </div>
        </template>
      </el-calendar>
    </el-card>
    <!-- 查阅预约时间 -->
    <el-dialog
      :title="seatTimeData.date"
      :visible.sync="bookingTimeStatus"
      width="500px"
      :before-close="bookingTimeClose">
      <el-row>
        <el-col class="seat-col " :md="6" v-for="(item,index) in seatTimeData.list" :key="index">
          <div class="title">{{item.time}}</div>
          <div :class="{'text color-danger':item.count==0,'text color-success':item.count>0}">
            {{item.count?'剩餘 '+item.count+' 坐席':'已預約滿'}}
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: "calendar",
    data() {
      return {
        queryDb:{
          storeId:1,
          time:'',
        },
        time:'',
        storeList:[],
        seatTimeData:{
          list:[],
          date:'',
        },
        bookingTimeStatus:false,
        loading:false,
        calendarLoading:false,
        calendarData: [
          // {
          //   months: '10',
          //   days: ['01','02','03','04'],
          //   things: '已約满'
          // },
        ],
      }
    },
    mounted() {
      let that = this
      that.getStoreList()
      that.queryDb.time = that.$atk.date_format()
    },
    methods:{
      // 預約時間列表按鈕
      bookingTimeList(key = '01'){
        let that = this
        let timeDb = that.time.split('-')
        let dateDb = timeDb[0]+'-'+timeDb[1]+'-'+key
        let param = that.$atk.json_to_url({
          dates: dateDb,
          storeId:that.queryDb.storeId
        });
        that.calendarLoading = true
        that.$http.GET("/v1/booking/getSeatInfo?"+param, (ret)=> {
            that.calendarLoading = false
            if (ret.errcode == 0) {
              that.seatTimeData.list = ret.data
              that.seatTimeData.date = dateDb
              that.bookingTimeStatus = true
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          }, (err)=> {
            that.calendarLoading = false
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
      //处理时间
      handleTime(s) {
        return s < 10 ? '0' + s : s
      },
      bookingTimeClose(close){
        close()
      },
      // 查詢按鈕
      queryBin(){
        this.loading = true
      },
      // 获取門店列表
      getStoreList() {
        let that = this;
        this.$http.GET(
          "/v1/booking/getStoreList?w=1",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data.list
              for(let i in list){
                that.storeList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].title,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      }
    },
    watch:{
      'queryDb.time':function () {
        this.time = this.$atk.formatDate(this.queryDb.time)
      }
    },
    // created(){
    //   let that = this
    //   that.$nextTick(() => {
    //     // 点击前一个月
    //     let prevBtn = document.querySelector(
    //       ".el-calendar__button-group .el-button-group>button:nth-child(1)"
    //     );
    //     prevBtn.addEventListener("click", e => {
    //       let d = new Date(that.value);
    //       let resDate = d.getFullYear() + '-' + that.handleTime((d.getMonth() + 1));
    //       console.log('点击前一个月',resDate)
    //     })
    //     //点击下一个月
    //     let nextBtn = document.querySelector(
    //       ".el-calendar__button-group .el-button-group>button:nth-child(3)"
    //     );
    //     nextBtn.addEventListener("click", e => {
    //       let d = new Date(that.value);
    //       let resDate = d.getFullYear() + '-' + that.handleTime((d.getMonth() + 1));
    //       console.log('点击下一个月',resDate)
    //     })
    //     //点击今天
    //     let todayBtn = document.querySelector(
    //       ".el-calendar__button-group .el-button-group>button:nth-child(2)"
    //     );
    //     todayBtn.addEventListener("click", e => {
    //       let d = new Date(that.value);
    //       let resDate = d.getFullYear() + '-' + that.handleTime((d.getMonth() + 1));
    //       console.log('点击今天',resDate)
    //     })
    //   });
    // }
  }
</script>

<style>
  .el-calendar__header .el-calendar__button-group{
    display: none;
  }
  .el-calendar-table td.is-selected {
    background-color: #e9e36f;
  }
  .el-backtop, .el-calendar-table td.is-today {
    color: #050505;
  }
</style>
<style scoped lang="scss">
  .calendar-day{
    text-align: center;
    line-height: 10px;
    font-size: 12px;
  }
  .is-selected{
    font-size: 10px;
    margin-top: 5px;
    text-align: center;
  }
  #calendar .el-button-group>.el-button:not(:first-child):not(:last-child):after{
    content: '当月';
  }
  .seat-col{
    height: 50px;
    padding: 6px;
    text-align: center;
    border: 1px solid #EBEEF5;
    .title{
      font-size: 16px;
      font-weight: 900;
    }
    .text{
      font-size: 12px;
    }
  }
</style>
